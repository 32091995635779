$green-1: #27742d;
$red-1: #db1020;
$yellow-1: #ffd700;

$black: #000000;
$gray-1: #080908;
$gray-2: #333030;
$gray-3: #656865;
$gray-4: #707070;
$gray-5: #c5c5c5;
$gray-6: #f4f4f4;
$white: #ffffff;

$shadow-1: #e3e3e3;

$primary: $green-1;
$secondary: $red-1;
$tertiary: $yellow-1;
