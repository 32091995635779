@use 'responsive' as *;

@font-face {
    font-family: 'Darwin';
    font-weight: 500;
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin-Bold.otf') format('opentype');
}

// no medum = 400 font-weight

@font-face {
    font-family: 'Darwin';
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Darwin';
    font-weight: 200;
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Darwin';
    font-weight: 100;
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin-ExtraLight.otf') format('opentype');
}

@font-face {
    font-family: 'Darwin';
    font-style: italic;
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'Darwin';
    font-style: italic;
    font-weight: 500;
    src: url('/assets/fonts/Darwin/Los\ Andes\ -\ Darwin-BlackItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url('/assets/fonts/Roboto/Roboto-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url('/assets/fonts/Roboto/Roboto-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url('/assets/fonts/Roboto/Roboto-Light.ttf') format('opentype');
}

$base-font-stack: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    'Helvetica Neue', sans-serif;

$primary: Darwin, $base-font-stack;

$secondary: Roboto, $base-font-stack;

.font-family-primary {
    font-family: $primary;
}

.font-family-secondary {
    font-family: $secondary;
}

.Typography__ {
    &italic {
        font-style: italic;
    }

    &condensed {
        font-weight: 600;
    }

    &bold {
        font-weight: 500;
    }

    &medium {
        font-weight: 400;
    }

    &regular {
        font-weight: 300;
    }

    &light {
        font-weight: 200;
    }

    &extra-light {
        font-weight: 100;
    }

    &title-1 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 30pt;
        @include md {
            font-size: 44pt; //65pt
        }
    }

    &title-2 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 37pt;
    }

    &title-3 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 34pt;
    }

    &title-4 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 23pt;
        @include md {
            font-size: 32.5pt;
        }
    }

    &title-5 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 23pt;
    }

    &title-6 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 20pt;
    }

    &label-1 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 19pt;
    }

    &label-2 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 13pt;
        @include md {
            font-size: 19pt;
        }
    }

    &label-3 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 16pt;
    }

    &label-4 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 11pt;
        @include md {
            font-size: 15pt;
        }
    }

    &label-5 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 14pt;
    }

    &label-6 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 11pt;
        @include md {
            font-size: 13.5pt;
        }
    }

    &label-7 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 13.5pt;
    }

    &label-8 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 12pt;
    }

    &label-9 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 12pt;
    }

    &label-10 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 11pt;
    }

    &label-11 {
        @extend .no-margin;
        @extend .font-family-primary;
        font-size: 11pt;
    }

    &label-12 {
        @extend .no-margin;
        @extend .font-family-secondary;
        font-size: 9pt;
    }
}
