@use '/src/styles/colors';
@use '/src/styles/responsive' as *;

.AboutSection--root {
    color: colors.$gray-2;
    display: grid;
    background-color: colors.$gray-6;
    grid-gap: 20px;
    padding-top: 64px;
    padding-bottom: 64px;
    margin-top: 32px;
    margin-bottom: 32px;
    > .title {
        text-transform: uppercase;
    }
    @include md {
        grid-template-columns: 1fr 1fr;
    }

    > .grid-item-1 {
        @include md {
            grid-column-start: span 2;
        }
    }

    > .grid-item-2 {
        > .logo {
            padding-bottom: 8px;
        }
        > .short-info-text {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        > .list-title {
            padding-top: 12px;
            padding-bottom: 8px;
        }

        > .list {
            padding-bottom: 8px;
            > .item {
                padding-top: 4px;
                padding-bottom: 4px;
                display: flex;
                align-items: center;
                gap: 12px;
                > .icon {
                    width: 1em;
                    height: 1em;
                    font-size: 28px;
                }
            }
        }
    }

    .grid-item-3 {
        > .long-info-title {
            color: colors.$primary;
        }
        > .long-info-text {
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }
}
