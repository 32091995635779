@use '/src/styles/responsive' as *;
@use '/src/styles/colors';
.Footer--root {
    background-color: colors.$black;
    color: colors.$white;
    a {
        color: colors.$white;
        text-decoration: none;
    }
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 24px;
    @include sm {
        padding-right: 48px;
    }

    @include lg {
        padding-right: 120px;
    }

    @include md {
        flex-direction: row;
        justify-content: space-between;
    }
    > .column {
        padding: 8px 42px;
        .title {
            text-transform: uppercase;
        }
        @include md {
            padding: 48px 16px;
        }
    }
    li {
        list-style: none;
    }
    .logo-container {
        background: url('/svgs/logo-bg.svg') no-repeat;
        background-size: contain;
        margin-bottom: 64px;
        > img {
            width: 80%;
            padding: 160px 16px 120px;
            @include sm {
                width: 314px;
            }
        }

        @include md {
            margin-bottom: 0px;
            > img {
                padding-top: 130px;
                padding-bottom: 104px;
                padding-left: 16px;
                padding-right: 50px;
            }
        }
    }
    ul {
        padding: 0px;
        margin: 0px;
    }
    > li > .social-platforms {
        padding: 8px 0px;
        display: flex;
        > li:first-child {
            padding-left: 0px;
        }
        > li:last-child {
            padding-right: 0px;
        }
        > li {
            padding: 4px;
            > a > svg {
                width: 1em;
                height: 1em;
                font-size: 56px;
            }
        }
    }
}

.FooterColumn--root {
    > .title {
        margin-bottom: 6px;
    }

    > nav > .list > li {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}
