@use 'responsive' as *;

.padding-sides-1 {
    padding-left: 24px;
    padding-right: 24px;
    @include sm {
        padding-left: 48px;
        padding-right: 48px;
    }

    @include lg {
        padding-left: 120px;
        padding-right: 120px;
    }
}

.section-gap {
    padding-top: 32px;
    padding-bottom: 32px;
}
