@use '/src/styles/colors';
@use '/src/styles/responsive' as *;
@use '/src/styles/typography' as *;

.FeedbackSection--root {
    > .container {
        box-shadow: 0px 0px 8px 2px colors.$shadow-1;
        padding: 32px 16px;
        @include sm {
            padding: 48px 40px;
        }
        @include md {
            padding: 60px 80px;
        }
        > .list-container {
            > .loader-container {
                position: relative;
                height: 180px;
            }
            > .list {
                list-style: none;
                padding: 0px 0px 48px;
                margin: 0;

                > li {
                    padding: 12px 0px;
                }
            }
        }
        > .primary-text {
            color: colors.$gray-1;
        }
        > .secondary-text {
            color: colors.$gray-4;
            padding: 8px 0px;
        }
        > .rating {
            padding: 12px 0px 8px 0px;
            display: flex;
            align-items: center;
            gap: 16px;
            > .rating-text {
                color: colors.$gray-1;
            }
        }
        .fullwidth {
            width: 100%;
        }
        .contact {
            @include md {
                width: 350px;
            }
        }
        .fieldset {
            padding: 8px 0px;
            > .field {
                @extend .Typography__label-10;
                @extend .Typography__regular;
                padding: 8px 4px;
                outline: none !important;
                border: 1px solid colors.$primary;
                resize: none;
                @include md {
                    min-width: 230px;
                }
            }
        }
        .inline {
            display: flex;
            flex-direction: column;
            gap: 8px;
            @include md {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
        .block {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        .submit-button {
            border-radius: 24px;
            padding: 12px 48px;
            margin-top: 16px;
        }
    }
}

.FeedbackItem--root {
    > .name {
        padding: 4px 0px 8px;
    }
}
