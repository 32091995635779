@use '/src/styles/colors';
@use '/src/styles/responsive' as *;
.Page--root {
    > header {
        position: fixed;
        background-color: colors.$white;
        z-index: 1;
        width: 100%;
    }

    > .container {
        padding-top: 176px;
        @include sm {
            padding-top: 136px;
        }
    }
}
