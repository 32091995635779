@use '/src/styles/colors';
@use '/src/styles/responsive' as *;

.Tabs--root {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    > li:first-child {
        padding-left: 0px;
    }
    > li:last-child {
        padding-right: 0px;
    }
    > li {
        padding: 4px 12px;
        @include md {
            padding: 4px 16px;
        }
        > .hr {
            height: 2px;
            margin: 4px 8px 0px;
            background-color: colors.$tertiary;
        }
        cursor: pointer;
    }

    > .active {
        color: colors.$primary;
    }
    > .inactive {
        color: colors.$gray-1;
    }
}
