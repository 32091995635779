@use '/src/styles/colors';

.ProductCard--root {
    > .row1 {
        color: colors.$secondary;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    > .row2 {
        color: colors.$gray-1;
        line-height: 28px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    > .contact-button {
        width: 100%;
        margin-top: 12px;
    }
}
