@use '/src/styles/colors';
@use '/src/styles/responsive' as *;

.ProductSection--root {
    > .container {
        width: 100%;
        position: relative;
        > .header {
            // display: flex;
            // flex-direction: row;
            text-align: center;
            @include md {
                // text-align: unset;
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }

            .Tabs--root {
                justify-content: center;
                @include lg {
                    justify-content: flex-end;
                }
            }
        }
        > .products-container {
            > ul {
                margin: 0px;
                padding: 0px;

                // @include sm {
                //     margin: 30px 0px;
                // }

                > li {
                    padding: 20px 0px;
                    width: 100%;
                    display: inline-block;
                    @include rwd(480) {
                        width: 50%;
                        padding: 16px 8px;
                    }

                    @include md {
                        width: 33.3%;
                    }

                    @include rwd(1200) {
                        width: 25%;
                    }

                    @include rwd(1500) {
                        width: 20%;
                    }

                    @include rwd(2200) {
                        width: 16.6%;
                    }
                }
            }
        }
    }
}
